export function getFilteredPosts({ inputValue, posts }) {
  const query = inputValue;

  // return all filtered posts
  const filteredPosts = posts.filter((post) => {
    // destructure data from post frontmatter
    const { description, title, tags } = post.node.frontmatter;
    return (
      // standardize data with .toLowerCase()
      // return true if the description, title or tags
      // contains the query string
      description.toLowerCase().includes(query.toLowerCase()) ||
      title.toLowerCase().includes(query.toLowerCase()) ||
      (tags &&
        tags
          .join("") // convert tags from an array to string
          .toLowerCase()
          .includes(query.toLowerCase()))
    );
  });

  return {
    inputValue,
    filteredPosts,
  };
}
