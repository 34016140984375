import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./blog.module.css";
import moment from "moment";

import { BsChevronDoubleRight } from "@react-icons/all-files/bs/BsChevronDoubleRight";
import { Link } from "gatsby";

const blogTypeLayoutToUrlSlug = {
  post: "posts",
  calculator: "calculators",
};

const Blog = ({ date, title, description, blogType, slug, blogImage }) => {
  return (
    <Link to={`/${blogTypeLayoutToUrlSlug[blogType]}/${slug}/`}>
      <div className={styles.slider_card}>
        <img
          src={
            (blogImage !== null) & (blogImage !== undefined) ? blogImage : null
          }
          alt={title}
        />

        <div className={styles.slider_cardcontent}>
          <h4>{moment(date).format("DD MMMM YYYY")}</h4>
          <h2>{title}</h2>
          <p>{description}</p>
          <span className={`flex items-center justify-left text-purple-800`}>
            <span style={{ paddingRight: 8 }}>READ MORE</span>
            <BsChevronDoubleRight />
          </span>
        </div>
      </div>
    </Link>
  );
};

export default Blog;
